<template>
    <div>
        <Header></Header>
        <!--顶部banner开始-->
        <div class="aboutus-banner left"> <img src="~images/banner/dangdejianshe.jpg" width="100%" /> </div>

        <div class="party-box left clearfix">
            <div class="party-content clearfix">
               <div class="party-left left clearfix">
                <!--顶部轮播图部分开始-->
                <div class="party-top-box left clearfix">
                    <div class="party-top-left clearfix left">
                         <Carousel v-model="value1" loop v-bind:height="imgHeight" v-bind:dots="dots" autoplay>
                            <CarouselItem v-for="item in banner" v-bind:key="item.id">
                                <a v-bind:href="item.url" v-bind:title="item.title">
                                <div class="demo-carousel">
                                    <img :src="item.img_path" width="100%" />
                                    <div>{{item.title}}</div>
                                </div>
                                </a>
                            </CarouselItem>
                        </Carousel>
                    </div>

                    <div class="party-top-right clearfix right">
                        <a :href="'/#/party_building_detail?id='+item.id" v-for="item in twoHot" :key="item.id" :title="item.title">
                        <div class="party-top-right-li clearfix right">
                            <img :src="item.img_path" width="100%" />
                            <div>{{item.title}}</div>
                        </div>
                        </a>
                    </div>
                </div>

                <!--底部文章列表开始-->
                <div class="party-bottom-box left clearfix">
                    <a :href="'/#/party_building_detail?id='+item.id" v-for="item in artList" :key="item.id" :title="item.title">
                    <div class="party-bottom-li left clearfix">
                        <div class="party-bottom-left left"> <img :src="item.img_path" width="100%" /> </div>

                        <div class="party-bottom-right left">
                            <p class="party-bottom-title">{{item.title}}</p>
                            <p class="party-bottom-text">{{item.content}}</p>
                            <p class="party-bottom-date">发表于：{{item.add_time}}<button>查看详情</button></p>
                        </div>
                    </div>
                    </a>
                    <div class="page-box left clearfix">
                    <Page :total="totals" @on-change="getData" :page-size="pageSize"/>
                    </div>
                </div>
                <!--底部文章类表结束--->
               </div>
            </div>
        </div>

     <div class="left clearfix width-bai"> <Footer></Footer> </div>
</div>
</template>
<style scoped>
    .page-box{width:100%;line-height:80px;text-align:center;}
    .party-bottom-date button{width:80px;font-size:13px;color:#fff;cursor:pointer;border:none;border-radius:5px;line-height:30px;background:rgb(242,151,0);float:right;margin-right:20px;}
    .web-content-right ul li img{width:50px;min-height:50px;}
     .web-video-headimg label img{width:25px;height:25px;border-radius:50%;}
    .web-video-headimg label{float:left;margin-left:10px;line-height:25px;font-size:12px;}
    .web-video-headimg{width:100%;height:30px;}
    .web-content-right-views span:last-child{color:#999;}
    .web-content-right-views span:first-child{font-size:16px;color:#999;}
    .web-content-right-views{float:right;margin-right:10px;}
    .web-content-right ul li{float:left;width:100%;height:73px;border-bottom:1px solid #f8f8f8;}
    .web-content-right ul li:hover{color:#ff4d4d;}
    .web-content-right ul li div:last-child{width:80%;}
    .web-content-right ul li div p:first-child{padding-left:10px;font-size:15px;line-height:25px;margin-top:10px;margin-bottom:10px;}
    .web-content-right ul li div:first-child{margin-top:10px;width:50px;min-height:50px;overflow:hidden;}
    .web-content-right ul li div{float:left;}
    .party-right-title{float:left;line-height:40px;border-bottom:1px solid #eee;font-weight:bold;width:100%;font-size:15px;}
    .party-bottom-date{padding-left:20px;line-height:40px;font-size:15px;color:#999;}
    .party-bottom-text{padding-left:20px;padding-right:20px;line-height:30px;color:#666;font-size:15px;height:130px;}
    .party-bottom-title{line-height:40px;font-weight:bold;font-size:16px;padding-left:20px;}
    .party-bottom-right{width:1050px;height:220px;margin-left:20px;}
    .party-bottom-left img{min-height:220px;border-radius:5px 0 0 5px;}
    .party-bottom-left{width:350px;height:220px;}
    .party-bottom-li:hover{box-shadow: 0px 2px 5px rgb(242,151,0);color:rgb(242,151,0);}
    .party-bottom-li{width:100%;height:220px;box-shadow: 0px 2px 5px #cccccc;cursor:pointer;margin-top:15px;margin-bottom:15px;border-radius:5px;}
    .party-bottom-box{width:100%;min-height:400px;margin-top:10px;}
    .party-top-right-li div{position:absolute;z-index:200;bottom:0;color:#eee;font-size:13px;padding-left:2%;left:0;right:0;width:100%;line-height:35px;background:url('~images/default.png');}
    .party-top-right-li img{min-height:190px;}
    .party-top-right-li{width:100%;height:190px;position:relative;overflow:hidden;margin-bottom:20px;margin-right:10px}
    .demo-carousel div{position:absolute;z-index:200;bottom:0;color:#fff;font-size:15px;padding-left:2%;left:0;right:0;width:100%;line-height:40px;background:url('~images/default.png');}
    .demo-carousel img{width:100%;min-height:400px;}
    .demo-carousel{width:100%;height:400px;overflow:hidden;position:relative;}
    .party-top-left{width:76%;height:400px;}
    .party-top-right{width:22%;height:400px;margin-left:20px;}
    .party-top-box{width:100%;height:400px;}
    .party-right{width:20%;min-height:800px;margin-left:20px;}
    .party-left{width:100%;min-height:800px;}
    .party-content{width:1440px;min-height:800px;margin:auto;}
    .party-box{width:100%;min-height:800px;margin-top:20px;}
</style>

<script>

import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'

export default { 
   name:"",
   data() {
      return {
          value1:0,
          imgHeight:400,
          dots:'none',
          banner:'',
          twoHot:'',
          artList:'',
          page:1,
          totals:1,
          pageSize:10
      }
   },
   components:{
    Header,
    Footer
  },
  mounted() {
      this.getPageData(1);
  },
  methods: {
      getData(p){
          this.getPageData(p);
      },
      //获取分页数据
      getPageData(p){
            //获取录播banner
            this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_build.html?token='+this.$store.state.token+'&page='+p)
						.then(res=>{
                this.banner=res.data.result;
                this.twoHot=res.data.twoHot;
                this.artList=res.data.artList;
                this.totals=res.data.total;
            });
            
      }
  },

}
</script>


